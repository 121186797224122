<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>推广管理</a-breadcrumb-item>
    <a-breadcrumb-item>推广代理商</a-breadcrumb-item>
  </a-breadcrumb>
  <!-- 功能条 -->
  <div class="tool-view">
    <!-- 推广 -->
    <span class="tool-title">推广商：</span>
    <a-input v-model:value="search" style="width: 160px;" placeholder="请输入ID或名称" />
    <!-- 操作 -->
    <a-button type="primary" style="margin-left: 16px;" @click="handleSearch">查询</a-button>
    <a-button style="margin-left: 16px;" @click="handleReset">重置</a-button>
    <div style="flex: 1;"></div>
    <!-- 新建 -->
    <a-button type="primary" @click="touchEdit()">
      <plus-outlined />
      新建
    </a-button>
  </div>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.id"
    :pagination="pagination"
    :loading="isLoading"
    @change="handleTableChange"
  >
    <!-- 自定义行内容 -->
    <template #bodyCell="{ column, record }">
      <!-- 启用状态 -->
      <template v-if="column.key === 'status'">
        <a-switch
          v-model:checked="record.status"
          checked-children="开"
          un-checked-children="关"
          @change="statusChange($event, record)"
        />
      </template>
      <!-- 分成方式 -->
      <template v-if="column.key === 'cash_type'">
        {{ getDivideType(record) }}
      </template>
      <!-- 充值金额 -->
      <!-- <template v-if="column.key === 'agent_data_count' || column.key === 'y_pay_amount'">
        {{ record[column.key] === '-' ? '-' : $pub.KEEP_NUMBER_DECIMAL((record[column.key] || 0) / 100, 2, false, true) }}
      </template> -->
      <!-- 分成比例 -->
      <template v-if="column.key === 'cash_rate'">
        {{ record[column.key] + '%' }}
      </template>
      <!-- 未结算金额 -->
      <template v-if="column.key === 'not_cash_amount'">
        {{ record[column.key] === '-' ? '-' : (record.cash_type === 1 ? $pub.KEEP_NUMBER_DECIMAL((record[column.key] || 0) / 100, 2, false, true) : '-') }}
      </template>
      <!-- 操作 -->
      <template v-if="column.key === 'operation'">
        <a class="operation-item" @click="touchDetail(record)">数据详情</a>
        <a class="operation-item" @click="touchEdit(record)">账号设置</a>
        <a class="operation-item" @click="touchLibrarySettings(record)">片库设置</a>
        <!-- <a class="operation-item" @click="touchSettlement(record)" v-if="record.cash_type === 1">结算单</a> -->
      </template>
    </template>
  </a-table>
  <!-- 数据详情 -->
  <DataDrawer ref="RefDataDrawer"></DataDrawer>
  <!-- 新建 -->
  <NewDrawer ref="RefNewDrawer" @success="getPopularizeList"></NewDrawer>
  <!-- 结算单 -->
  <SettlementDrawer ref="RefSettlementDrawer"></SettlementDrawer>
  <!-- 片库设置 -->
  <LibrarySettingsDrawer ref="RefLibrarySettingsDrawer"></LibrarySettingsDrawer>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { useRoute } from 'vue-router'
import { message } from 'ant-design-vue'
import Pub from '@/utils/public'
import { DivideTypes } from '@/utils/constantList'
import { popularizeAgentList, agentStatus } from '@/api/operate'
import DataDrawer from './components-agent/DataDrawer.vue'
import LibrarySettingsDrawer from './components-agent/LibrarySettingsDrawer.vue'
import SettlementDrawer from './components-agent/SettlementDrawer.vue'
import NewDrawer from './components-agent/NewDrawer.vue'

// 数据详情
let RefDataDrawer = ref(null)
// 新增
let RefNewDrawer = ref(null)
// 结算单
let RefSettlementDrawer = ref(null)
// 片库设置
let RefLibrarySettingsDrawer = ref(null)
// 加载
let isLoading = ref(false)
// 搜索
let search = ref(undefined)
// 数据源
let dataSource = ref([])
// 排序字段
let order = ref('y_pay_amount')
// 排序顺序
let orderType = ref('desc')
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '推广商ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: '推广代理商名称',
    dataIndex: 'name',
    key: 'name',
    width: 280
  },
  {
    title: '推广链接数',
    dataIndex: 'adv_link_count',
    key: 'adv_link_count'
  },
  {
    title: '累计充值金额',
    dataIndex: 'agent_data_count',
    key: 'agent_data_count',
  },
  {
    title: '昨日充值金额',
    dataIndex: 'y_pay_amount',
    key: 'y_pay_amount',
    sorter: true,
    defaultSortOrder: 'descend'
  },
  {
    title: '分成方式',
    dataIndex: 'cash_type',
    key: 'cash_type'
  },
  {
    title: '分成比例',
    dataIndex: 'cash_rate',
    key: 'cash_rate'
  },
  {
    title: '启用状态',
    dataIndex: 'status',
    key: 'status'
  },
  // {
  //   title: '未结算金额',
  //   dataIndex: 'not_cash_amount',
  //   key: 'not_cash_amount'
  // },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation'
  }
])

// 钩子函数
onBeforeMount (() => {
  // query带入uid字段
  const route = useRoute()
  if (route.query.id) {
    search.value = route.query.id
  }
  // 获取列表
  getPopularizeList()
})

// 查询
function handleSearch () {
  // 设置分页
  pagination.current = 1
  // 获取列表
  getPopularizeList()
}

// 获取分成类型
function getDivideType (record) {
  const type = DivideTypes.find(item => item.value === record.cash_type)
  return (type && type.text) || '-'
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  order.value = ''
  orderType.value = ''
  if (sorter.columnKey) {
    order.value = sorter.columnKey
    orderType.value = sorter.order === 'descend' ? 'desc' : 'asc'
  }
  getPopularizeList()
}

// 重置
function handleReset () {
  // 筛选项重置
  search.value = undefined
  // 分页重置
  pagination.current = 1
  getPopularizeList()
}

// 获取列表
function getPopularizeList () {
  isLoading.value = true
  const params = {
    keyword: search.value,
    order: order.value,
    sort: orderType.value,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  popularizeAgentList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      const list = data.data
      list.forEach(item => {
        item.status = Boolean(item.status)
      })
      dataSource.value = list
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  })
}

// 数据分成
function touchDetail (record) {
  RefDataDrawer.value.showDrawer(record)
}

// 账号新建编辑
function touchEdit (record) {
  RefNewDrawer.value.showDrawer(record)
}

// 片库设置
function touchLibrarySettings (record) {
  RefLibrarySettingsDrawer.value.showDrawer(record)
}

// 结算单
function touchSettlement (record) {
  RefSettlementDrawer.value.showDrawer(record)
}

// 切换启用状态
function statusChange (e, record) {
  isLoading.value = true
  const params = {
    id: record.id,
    status: Number(e)
  }
  agentStatus(params).then(res => {
    // isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      if (e) {
        message.success('开启成功')
      } else {
        message.success('关闭成功')
      }
    } else {
      message.error(res.message || msg)
    }
    getPopularizeList()
  }).catch(() => {
    // isLoading.value = false
    getPopularizeList()
  })
}
</script>

<style scoped>
.alert-view {
  margin-top: 20px;
}
.tool-view {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.table-view {
  margin-top: 30px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}
.operation-item {
  margin-right: 8px;
}
</style>