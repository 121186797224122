<template>
  <a-drawer
    :title="`结算单【${data.name}】`"
    width="800"
    :visible="visible"
    :closable="false"
    @close="onClose"
  >
    <!-- 功能条 -->
    <div class="tool-view">
      <!-- 日期： -->
      <span class="tool-title">日期范围：</span>
      <a-range-picker
        v-model:value="dateRange"
        :format="dateFormat"
        :allowClear="false"
        @change="onDateChange"
      />
    </div>
    <!-- 多选组件 -->
    <TableCheckbox
      sourceKey="id"
      :dataSource="dataSource"
      :selectedRowKeys="selectedRowKeys"
      :selectedRows="selectedRows"
      :pagination="null"
      :isLoading="isLoading"
      @select="selectAll"
    >
      <!-- 按钮文案 -->
      <template #checkbox><span></span></template>
      <!-- 列表 -->
      <a-table
        class="table-view"
        :data-source="dataSource"
        :columns="columns"
        :row-key="record => record.id"
        :pagination="null"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, getCheckboxProps: getCheckboxProps }"
        @change="handleTableChange"
      >
        <!-- 自定义行内容 -->
        <template #bodyCell="{ column, record }">
          <!-- 充值金额 -->
          <template v-if="column.key === 'pay_amount'">
            {{ record[column.key] === '-' ? '-' : $pub.KEEP_NUMBER_DECIMAL((record[column.key] || 0) / 100, 2, false, true) }}
          </template>
          <!-- 分成比例 -->
          <template v-if="column.key === 'cash_rate'">
            {{ record[column.key] === '-' ? '-' : $pub.KEEP_NUMBER_DECIMAL((record[column.key] || 0), 2, false, true) + '%' }}
          </template>
          <!-- 分成金额 -->
          <template v-if="column.key === 'cash_amount'">
            {{ record[column.key] === '-' ? '-' : $pub.KEEP_NUMBER_DECIMAL((record[column.key] || 0) / 100, 2, false, true) }}
          </template>
        </template>
      </a-table>
    </TableCheckbox>
    <!-- 尾部 -->
    <template #footer>
      <div class="footer-view">
        <span class="hint-info">共选择了<span class="hint-desc">{{ selectedRowKeys.length }}</span>天数据，总计分成金额<span class="hint-desc">{{ $pub.KEEP_NUMBER_DECIMAL(totalCashAmount / 100, 2, false, true) }}</span>元。</span>
        <div style="flex: 1;"></div>
        <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
        <a-button type="primary" @click="touchSubmit">创建结算单</a-button>
      </div>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { message } from 'ant-design-vue'
import dayjs from 'dayjs'
import { getNoCashList, cashCreate } from '@/api/operate'
import Loading from '@/components/Loading'
import TableCheckbox from '@/components/TableCheckbox'
import Pub from '@/utils/public'

// emit
const emit = defineEmits(['success'])
// 显示
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 当前数据
let data = ref({})
// 日期范围
let dateRange = ref([dayjs().subtract(30, 'day'), dayjs()])
// 日期format
const dateFormat = 'YYYY-MM-DD'
// 数据源
let dataSource = ref([])
// 选中
let selectedRowKeys = ref([])
let selectedRows = ref([])
// 总分成金额
let totalCashAmount = ref(0)
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '日期',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: '充值金额',
    dataIndex: 'pay_amount',
    key: 'pay_amount'
  },
  {
    title: '分成比例',
    dataIndex: 'cash_rate',
    key: 'cash_rate'
  },
  {
    title: '分成金额',
    dataIndex: 'cash_amount',
    key: 'cash_amount'
  }
])

// 显示
function showDrawer (record) {
  // 清空
  totalCashAmount.value = 0
  selectedRowKeys.value = []
  selectedRows.value = []
  dataSource.value = []
  dateRange.value = [dayjs().subtract(30, 'day'), dayjs()]
  // 记录
  data.value = record
  // 显示
  visible.value = true
  // 获取数据详情列表
  getList()
}

// 关闭
function onClose () {
  if (!isLoading.value) {
    pagination.current = 1
    visible.value = false
  }
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getList()
}

// 切换日期
function onDateChange () {
  // 设置分页
  pagination.current = 1
  // 数据
  getList()
}

// 获取数据详情列表
function getList () {
  // 获取数据
  isLoading.value = true
  const params = {
    project_agent_id: data.value.id,
    start_time: dateRange.value ? `${dateRange.value[0].format(dateFormat)}` : undefined,
    end_time: dateRange.value ? `${dateRange.value[1].format(dateFormat)}` : undefined
    // page: pagination.current,
    // page_size: pagination.pageSize
  }
  getNoCashList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      // 清空
      totalCashAmount.value = 0
      selectedRowKeys.value = []
      selectedRows.value = []
      // 重置列表数据
      if (data.length) { data[0].disabled = true }
      dataSource.value = data
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  })
}

// 全选
function selectAll (rowKeys, rows) {
  onSelectChange(rowKeys, rows)
}

// 多选处理
function onSelectChange (rowKeys, rows) {
  selectedRowKeys.value = rowKeys
  selectedRows.value = rows
  totalCashAmount.value = 0
  selectedRows.value.forEach(item => {
    totalCashAmount.value += Number(item.cash_amount)
  })
}

// 禁用选中
function getCheckboxProps (record) {
  return {
    // 禁止选汇总
    disabled: record.date === '汇总'
  }
}

// 创建结算单
function touchSubmit () {
  if (selectedRowKeys.value.length) {
    isLoading.value = true
    const params = {
      data_ids: selectedRowKeys.value
    }
    cashCreate(params).then(res => {
      isLoading.value = false
      const { code, data, msg } = res
      if (code === 0) {
        message.success('创建成功')
        emit('success')
        onClose()
      } else {
        message.error(res.message || msg)
      }
    }).catch(() => {
      isLoading.value = false
      message.error('创建失败')
    })
  } else {
    message.error('未选中结算单数据')
  }
}

// 抛出
defineExpose({
  showDrawer
})

</script>

<style scoped>
.tool-view {
  width: 100%;
  display: flex;
  align-items: center;
}
.table-view {
  margin-top: 30px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}
.operation-item {
  margin-right: 8px;
}
.footer-view {
  display: flex;
  align-items: center;
}
.hint-info {
  color: #A7A7A7;
}
.hint-desc {
  color: #323232;
}
</style>