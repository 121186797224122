<template>
  <a-drawer
    :title="id ? '编辑代理商' : '新建代理商'"
    width="700"
    :visible="visible"
    :closable="false"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <!-- from -->
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 16 }"
    >
      <!-- 名称 -->
      <a-form-item
        label="名称"
        name="name"
        :rules="[{ required: true }]"
      >
        <a-input
          v-model:value="formState.name"
          placeholder="请输入"
          :maxlength="30"
        />
      </a-form-item>
      <!-- 分成方式 -->
      <a-form-item
        label="分成方式"
        name="cash_type"
        :rules="[{ required: true }]"
      >
        <a-select
          class="form-input"
          v-model:value="formState.cash_type"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :disabled="!!id"
        >
          <a-select-option
            v-for="item in DivideTypes"
            :key="item.value"
            :value="item.value"
          >
            {{ item.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 分成比例 -->
      <a-form-item
        name="cash_rate"
        :rules="[{ required: true, validator: validatorProportion }]"
      >
        <template #label>
          分成比例
          <a-tooltip>
            <template #title>按充值金额进行比例分成。修改分成比例只对修改后的数据生效。</template>
            <info-circle-outlined style="color: #555; margin-left: 2px" />
          </a-tooltip>
        </template>
        <a-input
          class="form-input"
          v-model:value="formState.cash_rate"
          placeholder="请输入"
          suffix="%"
          :disabled="!!id"
        />
      </a-form-item>
      <!-- 自建推广链接 -->
      <a-form-item
        label="自建推广链接"
        name="is_create"
        :rules="[{ required: true }]"
      >
        <a-switch
          v-model:checked="formState.is_create"
          checked-children="开"
          un-checked-children="关"
        />
      </a-form-item>
      <!-- 推广渠道 -->
      <a-form-item
        v-if="formState.is_create"
        label="推广渠道"
        name="channel"
        :rules="[{ required: true }]"
      >
        <a-select
          mode="multiple"
          v-model:value="formState.channel"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="item in ChannelTypes"
            :key="item.value"
            :value="item.value"
          >
            {{ item.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 客户端 -->
      <a-form-item
        v-if="formState.is_create"
        label="客户端"
        name="client"
        :rules="[{ required: true }]"
      >
        <a-select
          mode="multiple"
          v-model:value="formState.client"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="item in ClientTypes"
            :key="item.value"
            :value="item.value"
          >
            {{ item.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 用户名 -->
      <a-form-item
        name="username"
        :rules="[{ required: true, message: '请输入用户名' }]"
      >
        <template #label>
          用户名
          <a-tooltip>
            <template #title>代理商登录代理商后台查看数据的账号</template>
            <info-circle-outlined style="color: #555; margin-left: 2px" />
          </a-tooltip>
        </template>
        <a-input
          placeholder="请输入"
          v-model:value="formState.username"
          :maxlength="20"
        />
      </a-form-item>
      <!-- 密码 -->
      <a-form-item
        label="密码"
        name="password"
        :rules="[{ required: true, validator: validatorPassword }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.password"
        />
      </a-form-item>
      
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
      <a-button type="primary" @click="touchSubmit">确定</a-button>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { reactive, ref } from 'vue'
import Loading from '@/components/Loading'
import Pub from '@/utils/public'
import { message } from 'ant-design-vue'
import md5 from 'js-md5'
import { PasswordRule, DivideTypes, ChannelTypes, ClientTypes } from '@/utils/constantList'
import { popularizeAgentDetail, popularizeAgentEdit } from '@/api/operate'

// emit
const emit = defineEmits(['success'])
// from
const RefForm = ref(null)
// 显示
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 短剧列表
let dramas = ref([])
// id
let id = ref(undefined)
// 表单参数
const formState = reactive({
  // 名称
  name: undefined,
  // 分成方式
  cash_type: undefined,
  // 分成比例
  cash_rate: undefined,
  // 自建推广链接
  is_create: false,
  // 客户端
  client: undefined,
  // 推广渠道
  channel: undefined,
  // 用户名
  username: undefined,
  // 密码
  password: undefined
})

// 显示
function showDrawer (data) {
  // 显示
  visible.value = true
  if (data && data.id) {
    id.value = data.id
    // 获取详情
    getPopularizeAgentDetail()
  }
}

// 关闭
function onClose () {
  if (!isLoading.value) {
    resetFields()
    id.value = undefined
    visible.value = false
  }
}

// 重置
function resetFields () {
  RefForm.value.resetFields()
  formState.channel = undefined
  formState.client = undefined
}

// 获取代理商详情
function getPopularizeAgentDetail () {
  popularizeAgentDetail({
    id: id.value
  }).then(res => {
    const { code, data } = res
    if (code === 0) {
      // 名称
      formState.name = data.name
      // 分成方式
      formState.cash_type = data.cash_type
      // 分成比例
      formState.cash_rate = data.cash_rate
      // 自建推广链接
      formState.is_create = data.is_create ? true : false
      // 客户端
      formState.client = data.client
      // 推广渠道
      formState.channel = data.channel
      // 用户名
      formState.username = data.user.username
      // 密码
      formState.password = PasswordRule
    }
  })
}

// 效验 - 分成比例
function validatorProportion (rule, value) {
  if (!value) {
    return Promise.reject('请输入${label}')
  } else if (!Pub.REG_IS_NUMBER(value)) {
    return Promise.reject('必须为正数')
  } else if (Pub.CHECK_NUMBER_DECIMAL(value, 2)) {
    return Promise.reject('最多 2 位小数')
  }
  return Promise.resolve()
}

// 效验 - 密码
function validatorPassword (rule, value) {
  if (value && value === PasswordRule) {
    return Promise.resolve()
  } else if (!value) {
    return Promise.reject('请输入${label}')
  } else if (!Pub.REG_IS_PASSWORD(value)) {
    return Promise.reject('请输入6-12位仅包含字母或数字的密码')
  }
  return Promise.resolve()
}

//  提交
function touchSubmit () {
  RefForm.value.validate().then((res => {
    // 上传数据
    const params = {
      id: id.value,
      ...formState,
      is_create: formState.is_create ? 1 : 0,
      password: formState.password === PasswordRule ? PasswordRule : md5(formState.password)
    }
    // 新增
    isLoading.value = true
    popularizeAgentEdit(params).then((res) => {
      isLoading.value = false
      if (res.code === 0) {
        message.success('提交成功')
        onClose()
        emit('success')
      } else {
        message.error(res.message)
      }
    }).catch(() => {
      isLoading.value = false
      message.error('提交失败')
    })
  })).catch((err) => {
    console.log(err)
  })
}

// 抛出
defineExpose({
  showDrawer
})

</script>

<style scoped>
.form-input {
  width: 50%;
}
</style>